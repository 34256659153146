import React, { Component } from "react";

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import '@aws-amplify/ui/dist/style.css';
import AWS from 'aws-sdk';
import AppStream from 'aws-sdk/clients/appstream';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';


import './App.css';


//Amplify.configure(awsconfig);

const refreshHandlers = {};

refreshHandlers[awsconfig.aws_cognito_issuer] = () => {
    return new Promise((resolve, reject) => {
        resolve({
            token: '',
            expires_at: '',
            identity_id: ''
        });
        window.location.assign(awsconfig.aws_cognito_issuer_domain);
    });
};

Amplify.configure({
  Auth: {
      region: awsconfig.aws_project_region,
      userPoolId: awsconfig.aws_user_pools_id,
      userPoolWebClientId: awsconfig.aws_user_pools_web_client_id,
      identityPoolId: awsconfig.aws_cognito_identity_pool_id,
      mandatorySignIn: true,
      refreshHandlers: refreshHandlers
  }
});


AWS.config.update({
  region: awsconfig.aws_cognito_region
});


function ExpireSessionModal(props) {
  const [show, setShow] = React.useState(false);

  async function expireSession(){
    const credentials = await Auth.currentCredentials();

    var params = {
      SessionId: props.sessionID /* required */
    };
    var request = new AWS.AppStream({
      credentials: Auth.essentialCredentials(credentials)
    }).expireSession(params, function(err, data) {
      if (err) console.log(err, err.stack); // an error occurred
      else     console.log(data);           // successful response
    });

    // create the promise object
    //var promise = await request.promise();
    //console.log(promise)
  }

  const handleExpire = () => {
    expireSession();
    setShow(false)
  };
  const handleCancel = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button className="button button3" onClick={handleShow}>
        Expire User Session
      </button>

      <Modal show={show} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>WARNING</Modal.Title>
        </Modal.Header>
        <Modal.Body><b>WARNING</b>: You're about to expire the user's session. This will kick them out of AppStream. Continue?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleExpire}>
            Expire
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
class User extends React.Component {

	render() {
  	const p = this.props;
  	return (
    	<div className="user">
        <span className="userListText">User Result</span>
        <div className="userinfo">
          <Alert variant='success'>
            Session was found for user.
          </Alert>
          <div><b>User ID:</b> {p.UserId}</div>
          <div><b>Associated Stack:</b> {p.StackName}</div>
          <div><b>Connection State:</b> {p.ConnectionState}</div>
          <div><b>State:</b> {p.State}</div>
          <div><b>Last Connection Start Time:</b> {p.StartTime.toLocaleString()}</div>
          <div><b>Expiration Time:</b> {p.MaxExpirationTime.toLocaleString()}</div>
          <div><b>Instance IP Address:</b> {p.NetworkAccessConfiguration.EniPrivateIpAddress}</div>
          <div><b>AWS Network Interface ID:</b> {p.NetworkAccessConfiguration.EniId}</div>
          <div><b>Session ID:</b> {p.Id} </div>
          <div><ExpireSessionModal sessionID={p.Id}/> </div>
        </div>
    	</div>
    );
  }
}

class UserList extends React.Component {

  render() {
      const p = this.props;

      if ( p.resultLoading ) {
        return (
          <Container>
            <div className="d-flex justify-content-center" ><Spinner animation="border"  /></div>
          </Container>
        )
      } else if (p.userinfoarray[0] !== undefined ) {
        return (
            <div className="userlist">
              <span className="userlisttext"></span>
              {p.userinfoarray.map(userinfo => <User key={userinfo.Id} {...userinfo}/>)}
            </div>
        );
      }
      else if (p.userinfoarray.length > 0) {
        return (
          <div className="userlist">
            <span className="userlisttext"></span>
            <Alert variant='danger'>
              Session for user not found. Search is case sensitive.
            </Alert>
          </div>
        );
      } else {
        return (
          <div className="userlist">
            <span className="userlisttext"></span>
          </div>
        )
      }
  }
}

class UserForm extends React.Component {

  state = { 
    stackName: '',
    fleetName: 'fleetName',
    userName: '',
    authenticationType: 'SAML',
    buttonLoading: false 
  };


  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ 
      buttonLoading: true
    });

    this.props.onLoad(true);


    const credentials = await Auth.currentCredentials();

    var listAssociatedStacksParams = {
      FleetName: this.state.fleetName
    };
  
    var listAssociatedStacksRequest = new AWS.AppStream({
      credentials: Auth.essentialCredentials(credentials)
    }).listAssociatedStacks(listAssociatedStacksParams);

    var listAssociatedStacksPromise = await listAssociatedStacksRequest.promise();
    console.log(listAssociatedStacksPromise)

    var stackName;

    if ( listAssociatedStacksPromise.Names.length > 0 ) {
      stackName = listAssociatedStacksPromise.Names[0]
    }
    else {
      stackName = "Not-Applicable"
    }

    var describeSessionsParams = {
      FleetName: this.state.fleetName, 
      StackName: stackName, 
      AuthenticationType: this.state.authenticationType,
      Limit: '1',
      UserId: this.state.userName
    };
  
    var describeSessionsRequest = new AWS.AppStream({
      credentials: Auth.essentialCredentials(credentials)
    }).describeSessions(describeSessionsParams);
  
    // create the promise object
    var describeSessionsRequestPromise = await describeSessionsRequest.promise();
    console.log(describeSessionsRequestPromise.Sessions[0])
    this.props.onSubmit(describeSessionsRequestPromise.Sessions[0]);
    this.props.onLoad(false);


    this.setState({ 
      buttonLoading: false
    });
    
  };
	render() {
  	return (
      <Form onSubmit={this.handleSubmit}>

        <div>
          <div><i>Use this tool to query user sessions for AppStream fleets in:</i></div>
          <div><i>Region: {this.props.awsRegion}</i></div>
          <div><i>Account: {this.props.accountNumber}</i></div>
        </div>
        <p></p>

        <Form.Group controlId="fleetName">
          <Form.Label>Fleet</Form.Label>
          <Form.Control 
            as="select" 
            value={this.state.fleetName}
            onChange={event => this.setState({ fleetName: event.target.value })}
            required
          >
            <option>Choose...</option>
            {this.props.fleets.map(fleet => <option key={fleet.Arn}>{fleet.Name}</option>)}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="userName">
          <Form.Label>User</Form.Label>
          <Form.Control
            type="text"
            value={this.state.userName} 
            placeholder="user@example.com" 
            onChange={event => this.setState({ userName: event.target.value })}
            required 
          />
        </Form.Group>
        <Form.Group controlId="authenticationType">
          <Form.Label>Authentication Type</Form.Label>
          <Form.Control 
            as="select" 
            value={this.state.authenticationType}
            onChange={event => this.setState({ authenticationType: event.target.value })}
            required
          >
            <option>SAML</option>
            <option>USERPOOL</option>
            <option>API</option>

          </Form.Control>
        </Form.Group>

        <div>
          <Button variant="primary" type="submit">
            {this.state.buttonLoading ?  'Loading…' : 'Submit'}
          </Button>
          
        </div>
      </Form>

    );
  }
}

class App extends Component {
  

  state = {
    userinfoarray: [],
    fleets: [],
    awsAccountNumber: "",
    awsRegion: "",
    resultLoading: false,
    pageLoading: true
  };

  addUser = (userinfo) => {
  	this.setState(prevState => ({
      userinfoarray: [],
    	userinfoarray: [userinfo],
    }));
  };

  resultLoading = (resultIsLoading) => {
  	this.setState(prevState => ({
    	resultLoading: resultIsLoading
    }));
  };

  async componentDidMount(){

    //const credentials = await Auth.currentCredentials();

    const credentials = await Auth.currentAuthenticatedUser()
      .then(user => this.setState({ user }))
      .catch(() => console.log("Not signed in"));

    var describeFleetsRequest = new AWS.AppStream({
      credentials: Auth.essentialCredentials(credentials)
    }).describeFleets();
  
    // create the promise object
    var describeFleetsRequestPromise = await describeFleetsRequest.promise();
    console.log(describeFleetsRequestPromise)

    this.setState({
      fleets: describeFleetsRequestPromise.Fleets,
      awsAccountNumber: describeFleetsRequestPromise.Fleets[0].Arn.split(':')[4],
      awsRegion: describeFleetsRequestPromise.Fleets[0].Arn.split(':')[3],
      pageLoading: false
    }
    )

    console.log(this.state.fleets)
  }

  render() {

    const { user } = this.state;


    if(this.state.pageLoading) {
      return (
        <Container>
            <div className="d-flex justify-content-center" ><Spinner animation="border"  /></div>
        </Container>
      );

    } else {
      return (
        <Container>
          <UserForm onLoad={this.resultLoading} onSubmit={this.addUser} fleets={this.state.fleets} accountNumber={this.state.awsAccountNumber} awsRegion = {this.state.awsRegion} />
          <UserList userinfoarray={this.state.userinfoarray} resultLoading={this.state.resultLoading} />
        </Container>
      );
    }
  }
  
}

export default App;