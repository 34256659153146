/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:075d30bf-2fe1-4510-93cf-f16997fc5392",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_miqk69EMu",
    "aws_user_pools_web_client_id": "4t620b1k979ilhud7unsuckakd",
    "oauth": {}
};


export default awsmobile;
